
/* ---- grid ---- */
.grid {
  background:#ffffff;
}

/* clear fix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* ---- .grid-item ---- */
.image-grid-sizer,
.image-grid-item{
  /* 3 columns by default */
  width: 33.333%;
}
/* make the image slightly larger than the image-grid */
.image-grid-item img{
    height: auto;
    display: block;
}
  
@media screen and (max-width: 1500px) {
    /* 1 column */
    .image-grid-sizer,
    .image-grid-item{
        width: 50%;
    }
}

@media screen and (max-width: 700px) {
    /* 1 column for smaller screens */
    .image-grid-sizer,
    .image-grid-item{
        width: 100%;
    }
}
.image-grid-item{
    display: block;
    max-width: 100%;
    float: left;
    position: static;
}

html, body {
    overflow: visible;
}
.cover-image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust the height of the cover image as needed */
    overflow: hidden;
}
  
.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%); /* Makes the image darker */
}

.cover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}
.album-date {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.album-title {
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 20px;
}

.see-album-btn {
    font-size: 1.2rem;
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.see-album-btn:hover {
    background-color: white;
    color: black;
}