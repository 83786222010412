body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:'Montserrat', sans-serif;
  overflow-y: hidden;
  background-color: white;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}
p {
  mix-blend-mode: exclusion;
}