.article {
    display: flex;
    width: min-content;
    height: min-content;
    gap: 15px;
}

.article__main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}