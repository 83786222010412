.image-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    right: -3px;
    gap: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    mix-blend-mode: exclusion;
    color: black;
    transition: 0.3s
}

.image-title:hover {
    cursor: pointer;
}